import React, { useEffect, useState, useContext } from 'react'
import { BlocksContext } from "../context/blocksContext"
import {AppBar, Toolbar, Typography, Container} from '@material-ui/core'


export const Menu = () => {
    const [icon, setIcon] = useState(false)
    const [collapseMenu, setCollapseMenu] = useState(false)
    const blocks = useContext(BlocksContext)

    const handleScroll = (event)=>{
        if (window.scrollY<30 && !collapseMenu){
            setCollapseMenu(false)
        } else {
            setCollapseMenu(true)
        }
    }
    useEffect(()=> {
        window.addEventListener('scroll', handleScroll, true);
        const interval = setInterval(()=>{
            clearInterval(interval)
            setIcon(!icon)
        }, 5000)
    }, [])


    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("data")
        console.log(popup)
        let num = 18
       
        if (blocks.blocks<num){
            blocks.setMenuClick(true)
            blocks.setBlocks(num+1)
            setTimeout(()=>{
                window.scrollTo({
                    top: document.querySelector("."+popup).offsetTop,
                    behavior: "smooth"
                })
                setTimeout(()=>{
                    blocks.setMenuClick(false)
                },500)
                

            },1500)
            
            
            
        } else {
            window.scrollTo({
                top: document.querySelector("."+e.currentTarget.getAttribute("data")).offsetTop,
                behavior: "smooth"
            })
        }
        if (document.querySelector('.menu_sandwich').classList.contains('opened')){
            document.querySelector('.menu_sandwich').classList.remove('opened')
            document.querySelector('.nav_menu').classList.remove('opened')
        }
    }
    const sandwichMenuClick = (e)=>{
        e.preventDefault()
        if(e.target.classList.contains('opened')){
            e.target.classList.remove('opened')
            document.querySelector('.nav_menu').classList.remove('opened')
        } else {
            e.target.classList.add('opened')
            document.querySelector('.nav_menu').classList.add('opened')
        }

    }

    const showPopup = (e)=>{
        e.preventDefault()
        if (!blocks.popup){
            blocks.setPopup(true)
            setTimeout(()=>{
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display="block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display="none"
                });
                
                document.querySelector('.'+e.target.getAttribute('data')).style.display="block"
            },0)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display="block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display="none"
            });
            
            document.querySelector('.'+e.target.getAttribute('data')).style.display="block"
        }
        
        
    }
    const scrollTop= (e) =>{
        e.preventDefault()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const menuClose = (e) =>{
        e.preventDefault()
        document.querySelector('.menu_sandwich').classList.remove('opened')
        document.querySelector('.nav_menu').classList.remove('opened')
    }

    return(
        <section className={!collapseMenu?"menu":"menu menu_scrool"}>
            <AppBar position="fixed" color="inherit" className="top_bar">
                <div  className="ct_main">
                    <div onClick={sandwichMenuClick} className="menu_sandwich"></div>
                    {/* <logo style={{cursor:"pointer"}} onClick={scrollTop}><img src="images/logo.svg" /></logo> */}
                    <logo><img src="images/logo.svg" /></logo>
                    <nav className="nav_menu">
                        <a href="#" onClick={clickMenu} data="infra"><span>Инфраструктура</span></a>
                        {/* <a href="#" onClick={clickMenu} data="plan_price"><span> Планировки и цены</span></a> */}
                        <a herf="#" onClick={clickMenu} data="how_buy"><span>Как купить</span></a>
                        <a href="#" onClick={clickMenu} data="contacts"><span>Контакты</span></a>
                        <div className="menu_closeform" onClick={menuClose}></div>
                    </nav>
                    <div className="menu_phone">
                        <div className="mp_wrap">
                            <a href="#" className="mp_number"><a href="tel:+74232222232" class="hl_phone roistat-aiva">8 (423) 222 22 32</a></a>
                            <a href="#" onClick={showPopup} data="pu_call"  className="mp_btn">Заказать звонок</a>
                        </div>
                        <div className="mp_loc"><span>р-н Патрокл, ул. Сочинская</span></div>
                    </div>
                </div>
            </AppBar>
        </section>
    )
}