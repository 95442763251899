import React, { useContext, useEffect, useState } from 'react'
import { BlocksContext } from "../context/blocksContext"
import parser from 'html-react-parser'

export const Main_screen = () => {
    const blocks = useContext(BlocksContext)
    const [subTitle, setSubTitle] = useState(false)

    const showPopup = (e)=>{
        e.preventDefault()
        if (!blocks.popup){
            blocks.setPopup(true)
            setTimeout(()=>{
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display="block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display="none"
                });
                
                document.querySelector('.'+e.target.getAttribute('data')).style.display="block"
            },0)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display="block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display="none"
            });
            
            document.querySelector('.'+e.target.getAttribute('data')).style.display="block"
        }
    }

    useEffect(()=> {
        const headers = { 'Content-Type': 'application/json' }
        let url = decodeURI(window.location.toString())
        url = url.split("?")
        if (url.length>1){
            fetch("https://айвазовскийдом.рф/titles.php?"+url[1], headers)
                .then(res => res.json())
                .then((result)=>{
                    console.log(result)
                    if (result.title && result.title!=""){
                        setSubTitle(result.title)
                    }
                })
        }
    },[])

    // useEffect(()=>{
    //     setTimeout(()=>{
    //         openPopupAction()
    //     },30000)
    // },[])

    const openPopupAction = () => {
        if (!blocks.popup){
            blocks.setPopup(true)
            setTimeout(()=>{
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display="block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display="none"
                });
                
                document.querySelector('.popup_action').style.display="block"
            },0)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display="block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display="none"
            });
            
            document.querySelector('.popup_action').style.display="block"
        }
    }

    return (
        <React.Fragment>
        <section className="main_screen h100">
            <div className='main_screen_inner'>
            {/* <a href="#" className="sale_left_btn" onClick={showPopup} data="pu_action">Распродажа</a> */}
                <div className="ms_inner ct_main">
                    <h1>ЖК «Айвазовский» </h1>
                    {/* {subTitle?
                        <sub className='sub_podmena'>{parser(subTitle)}</sub>
                        :
                        <sub>Современное жилье у моря с большим двором <br/> Принципиально новый уровень качества жизни <br/> от команды СИК «Девелопмент-Юг»</sub>
                    } */}
                    <sub>
                        <span>Дом сдан. Все квартиры распроданы</span>
                        <span className='sub_tt'>В других ЖК  от Девелопмент ЮГ еще остаются <br /><i>квартиры в продаже</i></span>
                    </sub>
                    <div className='ms_other'>
                        <div className='ms_other_title'>Ознакомиться с предложениями</div>
                        <div className='ms_other_href'>
                            <a target="_blank" href='https://xn----7sbaibl7ae3akfyq7f5b.xn--p1ai/'><img
                                src="images/ms_other_href_1.png"/></a>
                            <a target="_blank" href='https://xn--f1aefygdddbh.xn--p1ai/'><img
                                src="images/ms_other_href_2.png"/></a>
                            <a target="_blank" href='http://коммерция-вл.рф?utm_source=yandex_&utm_medium=LA&utm_campaign=Aiv'><img
                                src="images/ms_other_href_3.png"/></a>
                        </div>
                    </div>

                    {/* <div className="apart_info_box"><span>Квартиры от</span><b>2.5</b><l>МЛН РУБ.</l></div> */}
                    {/* <img className="head_plashka_top" src="images/head_plashka.png" /> */}
                </div>
            </div>
            <div className="ms_list ct_main">
                <div className="msl_el">
                    <img src="images/msl_ico1.svg" />
                    <span>20 минут <br/> до центра</span>
                </div>
                <div className="msl_el">
                    <img src="images/msl_ico2.svg" />
                    <span>Вид на море и <br/> лучшие рассветы</span>
                </div>
                <div className="msl_el">
                    <img src="images/msl_ico3.svg" />
                    <span>Большой двор</span>
                </div>
                <div className="msl_el">
                    <img src="images/msl_ico4.svg" />
                    <span>Открытый  <br/> паркинг</span>
                </div>
                <a target="_blank" href="https://domrfbank.ru" className="project_financing" style={{cursor:"pointer"}}></a>
                <img className="urban_ico" src="images/urban_ico1.png" />
                <img className="head_plashka" src="images/head_plashka.png" />
            </div>
            <div className="next_bl_ar"></div>
            {/*<a target="_blank" className="ws_fixed" href="https://wa.me/79508558406?text=hi"><img src="https://pngicon.ru/file/uploads/whatsapp-128x128.png"/></a>  */} 
            {/* <div className='popups popup_rgba'>
                <div className='popup_table'>
                    <div className='popup_cell'>
                        <div className='popup_action'>
                            <a href="#" class="closeform"></a>
                            <div className='popup_action_title'>
                                Ремонт в подарок <br />
                                При покупке квартиры
                            </div>
                            <form className='popup_action_form'>
                                <div className="form_in react_input_style">
                                    <TextField name="name" label="Ваше имя"  className="pu_name"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
        <div data-marquiz-id="60a4cbdd322297003e6b9cac"></div>
        </React.Fragment>
    )
}