import {React, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField'
import NativeSelect from '@material-ui/core/NativeSelect';
import Slider from "react-slick"
import InputMask from 'react-input-mask'
import Typography from '@material-ui/core/Typography';
import { useSendForm } from '../hooks/send-from.hook';

export const Popups = () => {

    const sendForm = useSendForm()
    const close = (e)=>{
        e.preventDefault()
        document.querySelector('body').classList.remove('overflow')
        document.querySelector('.popup_rgba').style.display="none"
        document.querySelectorAll('.popup_main').forEach(el => {
            el.style.display="none"
        });
    }

    const [actionParams, setActionParams] = useState({
        title: "",
        celtype: "",
    })

    const bankSlider= (e) => {
        let form = document.querySelector('.pu_ipot form')
        form.querySelector('.bank').value = document.querySelector('.banks_list .slick-center img').getAttribute('data')
    }
    const vznos= (e) => {
        let form = e.target.closest('form')
        form.querySelector('.vznos').value=e.target.value
    }
    const srok= (e) => {
        let form = e.target.closest('form')
        form.querySelector('.srok').value=e.target.value
    }
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={"slick-next slick-arrow"}
                style={{ ...style, display: "block", background: "red", width:"50px", height:"50px" }}
                onClick={onClick}
            />
        );
    }
    
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "block", background: "green", width:"50px", height:"50px" }}
            onClick={onClick}
            />
        );
    }
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        centerMode:true,
        variableWidth:true,
        slidesToScroll: 1,
        afterChange: bankSlider,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    //   responsive: [
    //     {
    //       breakpoint: 960,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         variableWidth: true,
    //       }
    //     }
    //     ]
    };

    //

    
    function isDescendant(child) {
        var node = child.parentNode;
        while (node.classList != undefined) {
            if (node.classList.contains('popup_main')) {
                console.log(node)
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    useEffect(()=>{
        if (document.querySelector('.blocks')!=null){
            document.querySelector('.blocks').addEventListener('click', (e)=>{
                let clicked = e.target
                console.log(isDescendant(clicked))
                if (!clicked.classList.contains('popup_main') && !isDescendant(clicked)  && e.target.nodeName.toLowerCase()!='canvas'){
                    document.querySelector('body').classList.remove('overflow')
                    document.querySelector(".popup_rgba").style.display="none";
                    document.querySelector(".popup_main").style.display="none";
                }
            })
        }
    },[])

    const showPopup = (e)=>{
        e.preventDefault()
        document.querySelector('body').classList.add('overflow')
        document.querySelector('.popup_rgba').style.display="block"
        document.querySelectorAll('.popup_main').forEach(el => {
            el.style.display="none"
        });
        console.log(e.currentTarget.getAttribute('data'))
        
        document.querySelector('.'+e.currentTarget.getAttribute('data')).style.display="block"
        setActionParams({
            title: e.currentTarget.getAttribute('title'),
            celtype: e.currentTarget.getAttribute('celtype')
        })
    }

    const successCallback = () => {
        document.querySelector('body').classList.add('overflow')
        document.querySelector('.popup_rgba').style.display="block"
        document.querySelectorAll('.popup_main').forEach(el => {
            el.style.display="none"
        });
        document.querySelector('.pu_thx').style.display="block"
	} 

    
    return (
        <section className="popups popup_rgba" style={{display: "none"}}>
            <div className="popup_table">
                <div className="popup_cell">
                    {/* popup action */}
                    <div className='popup_main popup_action' style={{display: "none"}}>
                        <a href="#" class="closeform" onClick={close}></a>
                        <div className='popup_action_inner'>
                            <div className='popup_action_title'>
                                Ремонт в подарок <br />
                                При покупке квартиры
                            </div>
                            <form className='popup_action_form'>
                                <div className="form_in react_input_style">
                                    <TextField name="name" label="Ваше имя"  className="pu_name"/>
                                </div>
                                <div className="form_in react_input_style">
                                    <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                        <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                    </InputMask>
                                </div>
                                <input type="hidden" class="text" value="Ремонт в подарок при покупке квартиры"/>
                                <div className="btn_style" celtype="getActionRemont" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                    <button>Узнать подробнее</button>
                                </div>
                            </form>
                        </div>
                        <img className='popup_action_img' src='images/popup_action_img.png' />
                    </div>
                    <div className="popup_main pu_call" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Закажите звонок</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <div className="form_in react_input_style">
                                <TextField name="time" label="Удобное время для звонка" className="pu_time" />
                            </div>
                            <input type="hidden" class="text" value="Заказать звонок"/>
                            <div className="form_in btn_style" celtype="getCall" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Заказать звонок</button>
                            </div>
                        </form>
                    </div>
                    <div className="popup_main pu_last_first" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Успей приобрести по выгодным условиям</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="1-комнатная квартира 38,4 кв.м<"/>
                            <div className="form_in btn_style" celtype="getLastFlat" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Заказать звонок</button>
                            </div>
                        </form>
                    </div>
                    <div className="popup_main pu_last_second" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Успей приобрести по выгодным условиям</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="Большая 2-комнатная квартира 57,4 кв.м."/>
                            <div className="form_in btn_style" celtype="getLastFlat" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Узнать подробнее</button>
                            </div>
                        </form>
                    </div>

                    <div className="popup_main pu_countdown" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Последние предложения по льготной ипотеке</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="Заказать звонок"/>
                            <div className="form_in btn_style" celtype="getCountdown" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Узнать подробнее</button>
                            </div>
                        </form>
                    </div>

                    <div className="popup_main pu_countdown" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Последние предложения по льготной ипотеке</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <div className="form_in react_input_style">
                                <TextField name="time" label="Удобное время для звонка" className="pu_time" />
                            </div>
                            <input type="hidden" class="text" value="Последние предложения по льготной ипотеке"/>
                            <div className="form_in btn_style" celtype="getCountdown" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Узнать подробнее</button>
                            </div>
                        </form>
                    </div>


                    
                    <div className="popup_main pu_sale pu_sale_ipot" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="ps_lside"> 
                            <div className="pu_tm"><span>узнайте подробнее <br/> об условиях акции </span> </div>
                            <form className="pu_form">
                                <div className="form_in react_input_style">
                                    <TextField name="name" label="Ваше имя"  className="pu_name"/>
                                </div>
                                <div className="form_in react_input_style">
                                    <TextField name="mail" label="Ваш e-mail" className="pu_mail" />
                                </div>
                                <div className="form_in react_input_style">
                                    <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                        <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                    </InputMask>
                                </div>
                                <input type="hidden" class="text" value="узнать подробнее"/>
                                <div className="form_in btn_style" celtype="getSaleIpot" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                    <button>узнать подробнее</button>
                                </div>
                            </form>
                        </div>
                        <div className="ps_rside"> 
                            <img src="images/sale_ipot_img.jpg" />
                        </div>
                        <div className="clr"></div>
                    </div>


                    <div className="popup_main pu_thx" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Спасибо</span></div>
                        <div class="thx_sub_tit">В ближайшее время с вами <br/> свяжется наш специалист </div>
                    </div>
                    
                    <div className="popup_main pu_commerc" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>получить планировки и цены по коммерческим помещениям</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <TextField name="mail" label="Ваш e-mail" className="pu_mail" />
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="получить планировки и цены по коммерческим помещениям"/>
                            <div className="form_in btn_style" celtype="getCommercPlans" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Получить планировки и цены</button>
                            </div>
                        </form>
                    </div>
                    
                    <div className="popup_main pu_question" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Задайте вопрос</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <TextField name="mail" label="Ваш e-mail" className="pu_mail" />
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <div className="form_in react_input_style react_textarea">
                                <TextField label="Текст вопроса" multiline rowsMax={1} className="pu_quest data-info" data="Текст вопроса:"/>
                            </div>
                            <input type="hidden" class="text" value="Задать вопрос"/>
                            <div className="form_in btn_style" celtype="getQuest" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Задать вопрос</button>
                            </div>
                        </form>
                    </div>
                    
                    <div className="popup_main pu_ipot" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Получите расчет ежемесячного платежа по ипотеке</span></div>
                        {/* <Slider className="banks_list" {...settings}>
                            <img data="Дом РФ" src="images/banks/dom_rf.jpg" />
                            <img data="ВТБ" src="images/banks/vtb.jpg" />
                            <img data="Сбер" src="images/banks/sber.jpg" />
                            <img data="Приморье" src="images/banks/primor.jpg" />
                            <img data="Газпром" src="images/banks/gasprom.jpg" />
                        </Slider> */}
                        {/* <div className="banks_list">
                            <img data="Дом РФ" src="images/banks/dom_rf1.jpg" />
                            <img data="ВТБ" src="images/banks/vtb.jpg" />
                            <img data="Сбер" src="images/banks/sber.jpg" />
                            <img data="Приморье" src="images/banks/primor.jpg" />
                            <img data="Газпром" src="images/banks/gasprom.jpg" />                            
                        </div> */}
                        <form className="pu_form">               
                            <div className="form_in gfp_in">
                                <NativeSelect  className="react_select_style pu_vznos" onChange={vznos}>
                                    <option value={""}>Первоначальный взнос</option>
                                    <option value={"от 300 т.р. до 500 т.р."}>от 300 т.р. до 500 т.р.</option>
                                    <option value="от 500 т.р. до 700 т.р.">от 500 т.р. до 700 т.р.</option>
                                    <option value="от 1 млн.р. до 1,2 млн.р.">от 1 млн.р. до 1,2 млн.р.</option>
                                    <option value="от 1,2 млн.р. до 1,5 млн.р.">от 1,2 млн.р. до 1,5 млн.р.</option>
                                    <option value="от 1,5 млн.р. до 2 млн.р.">от 1,5 млн.р. до 2 млн.р.</option>
                                </NativeSelect>
                            </div>           
                            <div className="form_in gfp_in">
                                <NativeSelect  className="react_select_style pu_time_sel" onChange={srok}>
                                    <option value={""}>На срок</option>
                                    <option value={"5 лет"}>5 лет</option>
                                    <option value="10 лет">10 лет</option>
                                    <option value="15 лет">15 лет</option>
                                    <option value="20 лет">20 лет</option>
                                    <option value="25 лет">25 лет</option>
                                    <option value="30 лет">30 лет</option>
                                </NativeSelect>
                            </div>
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="По лучите расчет ежемесячного платежа по ипотеке"/>
                            <input type="hidden" class="dop-info bank" data="Банк:" value="Дом РФ"/>
                            <input type="hidden" class="dop-info vznos" data="Первоначальный взнос:" value=""/>
                            <input type="hidden" class="dop-info srok" data="Срок:" value=""/>
                            <div className="form_in btn_style" celtype="getIpot" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Получить расчет</button>
                            </div>
                        </form>
                    </div>
                    <div className="popup_main pu_zastr" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <img src="images/dev_img.svg" />
                        <Typography>
                        <p>Корпорация «Девелопмент-Юг» работает на рынке недвижимости с 1995 года. За 29 лет сданы в эксплуатацию более 170 домов общей площадью 2 850 000 квадратных метров. Ежегодный ввод в эксплуатацию – 4 000 квартир.</p>
						<p>В 2022 году корпорация запустила два новых проекта в г. Владивосток: ЖК «Каштановый двор» и ЖК «Футурист» и вышла в новый регион г. Екатеринбург.</p>
						<p>Получили премии: «Надежный застройщик России 2022», «Лучший региональный застройщик России 2022» и стали двукратным победителем премии Urban Awards 2021 и 2022!</p>
						<p>Совместно с Российским аукционным домом корпорация первая в стране провела три успешных квартирных аукциона в элитном жилом комплексе Development-Plaza (г. Краснодар).</p>
						<p>В 2022 г. Девелопмент-Юг ввела в эксплуатацию более 252 000 кв. м. жилья. В строительстве находится 700 000 кв.м.</p>
                        <p>6 регионов присутствия: корпорация «Девелопмент-Юг» реализует свои объекты в Краснодарском, Приморском и Пермском крае, а также Ростовской, Астраханской и Свердловской областях. (Краснодар, Ростов-на-Дону, Астрахань, Пермь, Екатеринбург, Владивосток)</p>
                        <p>Входим в ТОП-5 застройщиков России по количеству регионов присутствия</p>
                         </Typography>
                    </div>
                    <div className="popup_main pu_rassr" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>ПОЛУЧИТЕ РАСЧЕТ ЕЖЕМЕСЯЧНОГО ПЛАТЕЖА ПО СЕМЕЙНОЙ ИПОТЕКЕ.</span></div>
                        <form className="pu_form">               
                            <div className="form_in gfp_in">
                                <NativeSelect  className="react_select_style pu_vznos" onChange={vznos}>
                                    <option value={""}>Первоначальный взнос</option>
                                    <option value={"от 300 т.р. до 500 т.р."}>от 300 т.р. до 500 т.р.</option>
                                    <option value="от 500 т.р. до 700 т.р.">от 500 т.р. до 700 т.р.</option>
                                    <option value="от 1 млн.р. до 1,2 млн.р.">от 1 млн.р. до 1,2 млн.р.</option>
                                    <option value="от 1,2 млн.р. до 1,5 млн.р.">от 1,2 млн.р. до 1,5 млн.р.</option>
                                    <option value="от 1,5 млн.р. до 2 млн.р.">от 1,5 млн.р. до 2 млн.р.</option>
                                </NativeSelect>
                            </div>           
                            <div className="form_in gfp_in">
                                <NativeSelect  className="react_select_style pu_time_sel" onChange={srok}>
                                    <option value={""}>На срок</option>
                                    <option value={"3 месяца"}>3 месяца</option>
                                    <option value="6 месяцев">6 месяцев</option>
                                    <option value="12 месяцев">12 месяцев</option>
                                </NativeSelect>
                            </div>
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="dop-info vznos" data="Первоначальный взнос:" value=""/>
                            <input type="hidden" class="dop-info srok" data="Срок:" value=""/>
                            <input type="hidden" class="text" value="ПОЛУЧИТЕ РАСЧЕТ ЕЖЕМЕСЯЧНОГО ПЛАТЕЖА ПО СЕМЕЙНОЙ ИПОТЕКЕ."/>
                            <div className="form_in btn_style" celtype="getRassr" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Получить расчет</button>
                            </div>
                        </form>
                    </div>

                    <div className="popup_main pu_matkap" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Получите расчет по материнскому капиталу</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="Получите расчет по материнскому капиталу"/>
                            <div className="form_in btn_style" celtype="getMatkap" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Получить расчет</button>
                            </div>
                        </form>
                    </div>

                    <div className="popup_main pu_dist" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Узнать подробнее о дистанционной сделке</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="Узнать подробнее о дистанционной сделке"/>
                            <div className="form_in btn_style" celtype="getDist" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Получить расчет</button>
                            </div>
                        </form>
                    </div>
                    
                    <div className="popup_main pu_viewing" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Узнать подробнее о предложении</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="Узнать подробнее о предложении"/>
                            <div className="form_in btn_style" celtype="getProsmotr" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Узнать подробнее</button>
                            </div>
                        </form>
                    </div>
                    
                    <div className="popup_main pu_flat" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm">
                            <span>Запишитесь на просмотр</span>
                            <div className="pu_area">
                                <span>Общая площадь: <b>73,63 м<sup>2</sup></b></span>
                                <span>Жилая площадь: <b>43,39 м<sup>2</sup></b></span>
                            </div>
                        </div>
                        <div className="rooms_area">
                            <span>
                                Жилые комнаты: <b>27,64 и 15,75 м<sup>2</sup></b> <br/>
                                Кухня: <b>12,48 м<sup>2</sup></b> <br/>
                                Санузел: <b>2,99 м<sup>2</sup></b>
                            </span>
                        </div>
                        <div className="flat_img"><img src="images/flat_img.jpg" /></div>
                        <form className="pu_form">
                            <div className="form_tit">Узнайте стоимость квартиры на сегодня</div>
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="Узнать стоимость квартиры"/>
                            <div className="form_in btn_style" celtype="getFlat" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Узнать стоимость</button>
                            </div>
                        </form>
                        <div className="clr"></div>
                    </div>
                    
                    <div className="popup_main pu_fin pu_pre_final" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Квартиры с отделкой в ЖК «Айвазовский»</span></div>
                        <ul className="pu_fin_list">
                            <span>Предчистовая отделка</span>
                            <li>
                                <span>входная металлическая дверь; </span>
                                <span>окна и балконные двери из ПВХ; </span>
                                <span>разведенная электрика;  </span>
                                <span>электрический счетчик; </span>
                                <span>тепловой счетчик поквартирный; </span>
                                <span>нижняя горизонтальная разводка отопления; </span>
                            </li>
                            <li>
                                <span>биметаллические радиаторы с терморегуляторами; </span>
                                <span>гидроизоляция мокрых мест;</span>
                                <span>пожарная сигнализация поквартирная;  </span>
                                <span>установлены перегородки между комнатами <br/> из газосиликатного блока 200 мм; </span>
                                <span>оштукатуренные стены;</span>
                                <span>стяжка пола 60 мм;</span>
                            </li>
                            
                        </ul>
                        <form className="pu_form">
                            <div className="form_tit">Узнайте стоимость квартиры с предчистовой отделкой</div>
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="Стоимость квартиры с предчистовой отделкой"/>
                            <div className="form_in btn_style" celtype="getOtdFlat" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Узнать стоимость</button>
                            </div>
                        </form>
                    </div>
                    
                    
                    <div className="popup_main pu_fin pu_final" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Квартиры с отделкой в ЖК «Айвазовский»</span></div>
                        <ul className="pu_fin_list">
                            <span>Предчистовая отделка +</span>
                            <li>
                                <span>бетонная стяжка пола, готовая для укладки финишного покрытия; </span>
                                <span>подготовка потолков; </span>
                            </li>
                            <li>
                                <span>выравнивание, грунтовка и штукатурка стен; </span>
                                <span>установка подоконников и выравнивание откосов.</span>
                            </li>                            
                        </ul>
                        <form className="pu_form">
                            <div className="form_tit">Узнайте стоимость квартиры с предчистовой отделкой</div>
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="Стоимость квартиры с предчистовой отделкой"/>
                            <div className="form_in btn_style" celtype="getOtdFlat" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Узнать стоимость</button>
                            </div>
                        </form>
                    </div>

                    
                    
                    <div className="popup_main pu_fin pu_repair_apart" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>КВАРТИРЫ С ОТДЕЛКОЙ WHITE BOX В ЖК «АЙВАЗОВСКИЙ</span></div>
                        <ul className="pu_fin_list">
                            <span>Что входит в отделку: </span>
                            <li>
                                <span>Дверь входная металлическая</span>
                                <span>Бетонная стяжка пола, готовая для укладки финишного покрытия </span>
                                <span>Возведение межкомнатных стен </span>
                                <span>Выравнивание, грунтовка и штукатурка стен</span>
                            </li>
                            <li>
                                <span>Поквартирная разводка электрики с учетом жизненных сценариев </span>
                                <span>Монтаж оконных откосов и установка подоконников</span>
                                <span>Подготовка потолков</span>
                            </li>
                        </ul>
                        <form className="pu_form">
                            <div className="form_tit">КВАРТИРЫ С ОТДЕЛКОЙ WHITE BOX В ЖК «АЙВАЗОВСКИЙ</div>
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <input type="hidden" class="text" value="Стоимость квартиры с предчистовой отделкой"/>
                            <div className="form_in btn_style" celtype="getOtdFlat" onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Узнать стоимость</button>
                            </div>
                        </form>
                    </div>

                    <div className="popup_main pu_doc" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Проектная документация</span></div>
                        <ul className="pd_list">
                            <li>
                                <a href="doc/Извещение 1329.pdf" target="_blank">Извещение 1329</a>
                                <a href="doc/Извещение 1333.pdf" target="_blank">Извещение 1333</a>
                                <a href="doc/ПЗУ.pdf" target="_blank">ПЗУ</a>
                                <a href="doc/Повтороное полож. заключение 1329.pdf" target="_blank">Повтороное полож. заключение 1329</a>
                                <a href="doc/Повтороное полож. заключение 1333.pdf" target="_blank">Повтороное полож. заключение 1333</a>
                                <a href="doc/Полож. заключение 1329.pdf" target="_blank">Полож. заключение 1329</a>
                                <a href="doc/Полож. заключение 1333.pdf" target="_blank">Полож. заключение 1333</a>
                                <a href="doc/РНС № 25-RU25304000-27-2021 от 30.032021 (1329).pdf" target="_blank">РНС № 25-RU25304000-27-2021 от 30.032021 (1329)</a>
                                <a href="doc/РНС № 25-RU25304000-28-2021 от 30.03.2021 (1333).pdf" target="_blank">РНС № 25-RU25304000-28-2021 от 30.03.2021 (1333)</a>
                            </li>
                            <li>
                                <a href="doc/ЗОС_ документы  28 04/ЗОС 1329.pdf" target="_blank">ЗОС 1329</a>
                                <a href="doc/ЗОС_ документы  28 04/ЗОС 1333.pdf" target="_blank">ЗОС 1333</a>
                                <a href="doc/Градпланы ЗУ (Патрокл)/ГПЗУ 1327.pdf" target="_blank">ГПЗУ 1327</a>
                                <a href="doc/Градпланы ЗУ (Патрокл)/ГПЗУ 1329.pdf" target="_blank">ГПЗУ 1329</a>
                                <a href="doc/Градпланы ЗУ (Патрокл)/ГПЗУ 1330.pdf" target="_blank">ГПЗУ 1330</a>
                                <a href="doc/Градпланы ЗУ (Патрокл)/ГПЗУ 1333.pdf" target="_blank">ГПЗУ 1333</a>
                                <a href="doc/Градпланы ЗУ (Патрокл)/ГПЗУ 1334.pdf" target="_blank">ГПЗУ 1334</a>
                                <a href="doc/Градпланы ЗУ (Патрокл)/ГПЗУ 1339.pdf" target="_blank">ГПЗУ 1339</a>
                                <a href="doc/Градпланы ЗУ (Патрокл)/ГПЗУ 1341.pdf" target="_blank">ГПЗУ 1341</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="popup_main pu_camera" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Смотрите ход строительства онлайн</span></div>
                        <ul className="pd_list">
                            <li>
                                <a href="https://tv.ivideon.com/camera/100-WxGG5ZvnJOvzaUDS7VX3pV/0/?lang=ru" target="_blank">Камера 1</a>
                                <a href="https://tv.ivideon.com/camera/100-nDcvRpZj1IjDoMlGl3m2hp/0/?lang=ru" target="_blank">Камера 2</a>
                            </li>
                            <li>
                                <a href="https://tv.ivideon.com/camera/100-bF2PzmViyDFwMULpMQNzFu/0/?lang=ru" target="_blank">Камера 3</a>
                                <a href="https://tv.ivideon.com/camera/100-9W5QbXY7BoUkw1JDsuysUQ/0/?lang=ru" target="_blank">Камера 4</a>
                            </li>
                        </ul>
                    </div>

                    <div className="popup_main pu_action" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>Распродажа <b>последних квартир</b></span></div>
                        {/* <ul className="pu_action_list">
                            <li>
                                <div className='pu_al__item'  onClick={showPopup} data="pu_action_det" title="Ипотека для молодых семей" celtype="getNullIpot">
                                    <img src="images/pu_action_list_img1.jpg" />
                                    <div className='pu_al__info'>Специальное предложение</div>
                                    <div className='pu_al__name'>Ипотека 1,2% для молодых семей</div>
                                    <div className='pu_al__podrobno'>Подробнее</div>
                                </div>
                            </li>
                            <li>
                                <div className='pu_al__item' onClick={showPopup} data="pu_action_det" title="Скидка на готовые квартиры" celtype="getVisitDiscont">
                                    <img src="images/pu_action_list_img2.jpg" />
                                    <div className='pu_al__info'>Скидка на готовые квартиры</div>
                                    <div className='pu_al__name'>Скидка до 1,1 млн. руб.</div>
                                    <div className='pu_al__podrobno'>Подробнее</div>
                                </div>
                            </li>
                            <li>
                                <div className='pu_al__item' onClick={showPopup} data="pu_action_det" title="ОБМЕН КВАРТИРЫ ПО ПРОГРАММЕ TRADE-IN" celtype="getRenewFlat">
                                    <img src="images/pu_action_list_img3.jpg" />
                                    <div className='pu_al__info'>Узнайте подробности акции</div>
                                    <div className='pu_al__name'>Обменяйте <br />старую квартиру <br />на новую</div>
                                    <div className='pu_al__podrobno'>Подробнее</div>
                                </div>
                            </li>
                        </ul> */}
                        <ul className='pu_action_list2'>
                            <li onClick={showPopup} data="pu_last_first">
                                <div className='pal__item'>
                                    <div className='pal__name'>1-комнатная квартира<br />&nbsp;</div>
                                    <div className='pal__img'><img src="images/pu_action_list2_img1.png"/></div>
                                    <div className='pal__info'>С большой комнатой и гардеробом</div>
                                    <div className='pal__area'>Общая площадь 38,4 кв.м</div>
                                    <div class="btn_style"><button>Получить расчеты</button></div>
                                </div>
                            </li>
                            <li onClick={showPopup} data="pu_last_second">
                                <div className='pal__item'>
                                    <div className='pal__name'>Большая 2-комнатная квартира</div>
                                    <div className='pal__img'><img src="images/pu_action_list2_img2.png"/></div>
                                    <div className='pal__info'>&nbsp;</div>
                                    <div className='pal__area'>Общая площадь 57,4 кв.м</div>
                                    <div class="btn_style"><button>Получить расчеты</button></div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="popup_main pu_action_det" style={{display: "none"}}>
                        <a href="#" className="closeform" onClick={close}></a>
                        <div className="pu_tm"><span>{actionParams.title}</span></div>
                        <form className="pu_form">
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                            <div className="form_in react_input_style">
                                <TextField name="mail" label="Ваш e-mail" className="pu_mail" />
                            </div>
                            <input type="hidden" class="text" value={actionParams.title}/>
                            <div className="form_in btn_style" celtype={actionParams.celtype} onClick={(e) => {sendForm.sendForm(e, successCallback)}}>
                                <button>Узнать подробнее</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
		</section>
    )
}